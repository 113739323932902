import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import HeroSection from '../Components/HomePage/Herosection/Herosection'
import BriefAbout from '../Components/HomePage/BriefAbout/BriefAbout'
import BriefFacilities from '../Components/HomePage/BriefFacilities/BriefFacilities'
import KeyMessage from '../Components/HomePage/KeyMessage/KeyMessage'
import TouristAttraction from '../Components/HomePage/TouristAttraction/TouristAttraction'
import Transportation from '../Components/HomePage/Transportation/Transportation'
import SlidingTexts from '../Components/HomePage/SlidingTexts/SlidingTexts'
import Testimonials from '../Components/HomePage/Testimonials/Testimonials'
import Footer from '../Components/Common/Footer/Footer'
function Home() {
  return (
    <div className='Home'>
        <Navbar/>
        <HeroSection/>
        <BriefAbout/>
        <KeyMessage/>
        <BriefFacilities/>
        <TouristAttraction/>
        <Transportation/>
        <SlidingTexts/>
        {/* <Testimonials/> */}
        <Footer/>

        
    </div>
  )
}

export default Home