import React, { useEffect, useState } from 'react';
import '../../../styles/GalleryList.css';
import LightGallery from 'lightgallery/react';
import LGFullscreen from 'lightgallery/plugins/fullscreen';
import lgShare from 'lightgallery/plugins/share';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-autoplay.css';
import lozad from 'lozad';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


function GalleryList({ gallery }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (gallery && gallery.acf && gallery.acf.gallery) {
      const imageLinks = gallery.acf.gallery.map(image => ({
        src: image.full_image_url,
        alt: image.alt_text || image.title
      }));
      setImages(imageLinks);
      setLoading(false);
    }
  }, [gallery]);

  useEffect(() => {
    const observer = lozad('.lozad', {
      loaded: function (el) {
        el.classList.add('lazy-loaded');
        el.addEventListener('load', () => {
          el.classList.remove('skeleton-loader');
        });
      },
      rootMargin: '-20px 0px',
    });
    observer.observe();

    return () => {
    };
  }, []);

  useGSAP(() => {


    const containers = document.querySelectorAll('.GalleryList');

    containers.forEach((container) => {
      // Apply ScrollTrigger to each container
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: "top 50%",
          end: "top 20%",
          toggleActions: "play none none none",
        }
      });

      tl.from(".GalleryList .img-container", {
        scale: 0.5,
        opacity: 0,
        duration: 0.7,
        stagger:0.5


      });
    });

  }, []);

  if (loading) {
    return (
      <div className="spinning_loading">
        <div class="loader"></div>
      </div>
    );
  }

  return (
    <div className='GalleryList'>
      {gallery && (
        <div className="container">
          {gallery.acf && gallery.acf.gallery && (
            <LightGallery
              plugins={[LGFullscreen, lgShare, lgZoom, lgAutoplay]}
              videojs={true}
              videojsOptions={{
                muted: true,
              }}
              speed={500}
            >
              {images.map((image, index) => (
                <a className="img-container" href={image.src} key={index}>
                  <img alt={image.alt || `img-${index}`} src={image.src} className='lozad skeleton-loader' />
                </a>
              ))}
            </LightGallery>
          )}
        </div>
      )}
    </div>
  );
}

export default GalleryList;
