import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../styles/TouristAttraction.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// Import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function TouristAttraction() {
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        {

            title: "Anangamala Eco-tourism",
            distance: "8.5km",
            content: "The wide range lush green forest occupies varieties of wild life and plants.Among the usual plants and wild life everyone can also spot medicinal plants . Bharathapuzha, one of the famous rivers in Kerala , is an attraction of this area.Beautiful landscape, rock valleys, the eye catching picturesque views , the pleasant atmosphere and valley breeze will be a never before experience to the tourist",
        },
        {
            title: "Kalamandalam",
            distance: "12km",
            content: "Kerala Kalamandalam, a deemed-to-be-University of Art and Culture by the Government of India, is a major centre for learning Indian performing arts, especially those that developed in the country's southern states, with special emphasis on those from Kerala. The institution, on the banks of the Bharathapuzha river, is situated in the small town of Cheruthuruthi in Thrissur district.",
        },
        {
            title: "Varikkassery mana",
            distance: "2.5km",
            content: "Varikkasseri Mana (Malayalam: വരിക്കാശേരി മന), alternatively known as Varikkumanchery Mana, is one of the oldest traditional aristocratic Namboothiri family houses (illam) in Kerala. Built in Kerala architectural style on a plot of land measuring approximately 4 acres, the building is located at Manissery, a village in Ottapalam in Palakkad. It is a popular shooting location for Malayalam films and several commercially successful films such as Devasuram, Aaraam Thampuran, Rappakal were filmed on its premises.",
        },
        {
            title: "Kalagramam",
            distance: "14Km",
            content: " The famous `Kalluvazhi chitta` took birth at Olappamanna. The annual cultural event Thaadiyarangu draws viewers from many places as it brings together different Kathakali artistes who enact scenes from different stories.Around 50 art forms are regularly practised and trained here and has earned for the village the title 'Kalagramam' [artistic village]",
        },
    ];


    // gsap 

    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".TouristAttraction",
                start: "top 50%",
                end: "top 20%",
                toggleActions: "play none none none",

            }
        })
        var split = new SplitType(".TouristAttraction .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.2,
            stagger: 0.05,

        });
        tl.to(".TouristAttraction .container .content p", {
            opacity: 1,
            duration: 1,


        }, "-=0.5");
        tl.to(".TouristAttraction .container .content span", {
            opacity: 0.2,
            duration: 2,


        }, "-=1");
        


    }, []);
    return (
        <div className='TouristAttraction'>
            <div className="container">
                <div className="row">
                    <div className="left-col"data-aos="zoom-in" data-aos-duration="1000">

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            effect={'fade'}
                            slidesPerView={1}
                            pagination={{
                                type: 'fraction',
                            }}
                            navigation={true}
                            modules={[EffectFade, Navigation, Pagination]}
                            className="mySwiper"
                            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="https://travel.4kerala.in/wp-content/uploads/2024/02/IMG_7686.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/Kutiyattam_the_oldest_living_theatre_tradition_2278 (1).jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="https://www.keralatourism.org/images/picture/large/Varikkasseri_Mana_Ottappalam_2748.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="img-container">
                                    <img src="img/53607200_2673178896032501_4768286637778010112_n.jpg" alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>



                    </div>
                    <div className="right-col">
                        <h3>Travel Attractions</h3>
                        <div className="content">
                            <h2>{slides[activeIndex].title}</h2>
                            <span>{slides[activeIndex].distance}</span>
                            <p>{slides[activeIndex].content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TouristAttraction