import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../../styles/BriefFacilities.css';

// import required modules
import { EffectCoverflow, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';


// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function BriefFacilities() {
    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".BriefFacilities",
                start: "top 50%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".BriefFacilities .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.2,
            stagger: 0.03,

        });
        tl.to(".BriefFacilities .container .content p", {
            opacity: 1,
            duration: 1,


        }, "-=2.5");
        tl.to(".BriefFacilities .container .content a", {
            opacity: 1,
            duration: 0.1,


        }, "-=2");
        


    }, []);
    return (
        <div className='BriefFacilities'>
            <div className="container">

                <div className="content" data-aos="fade" data-aos-duration="1000">
                    <h3>Our Facilities</h3>
                    <h2>Exceptional Amenities for an Unforgettable Stay
                    </h2>
                    <p>At Sahya Regency, we offer a variety of facilities to meet your every need. Enjoy our grand 400-pax banquet hall, fully-equipped 100-pax conference room, luxurious suites, high-speed Wi-Fi, air-conditioned rooms, and convenient laundry services. Our multi-cuisine restaurant and ample car parking ensure your comfort. Discover why Sahya Regency is perfect for both business and leisure travelers.</p>
                    <Link to={'/facility'}>Explore More</Link>
                </div>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1}
                    spaceBetween={60}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,
                    }}
                    data-aos="fade" data-aos-duration="1000"
                    breakpoints={{
                        576: {
                            slidesPerView: 2,

                        }

                    }}
                    speed={1000}
                    loop={true}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={{
                        type: 'fraction',
                    }}
                    navigation={true}
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>

                        <div className="card">
                            <div className="img-container">
                                <img src="img/WhatsApp Image 2024-08-06 at 12.16.35_73f5def3.jpg" />

                            </div>
                            <div className="facility">
                                <h2>Accommodation</h2>
                                <div className="more-details">
                                    <ul >
                                        <li><span><i class="fa-solid fa-check"></i></span>2 Suite Rooms</li>
                                        <li><span><i class="fa-solid fa-check"></i></span>1 Queen Suite</li>
                                        <li><span><i class="fa-solid fa-check"></i></span>9 Standard Rooms</li>
                                        <li><span><i class="fa-solid fa-check"></i></span>All Rooms are Air Conditioned</li>
                                        <li><span><i class="fa-solid fa-check"></i></span>Wi-Fi Available </li>
                                        <li><span><i class="fa-solid fa-check"></i></span>Laundry Services Available</li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/WhatsApp Image 2024-08-06 at 12.16.31_2f7dce78.jpg" />

                            </div>
                            <div className="facility">
                                <h2>Event and Meeting Spaces</h2>
                                <div className="more-details">
                                    <ul>
                                        <li><span><i class="fa-solid fa-check"></i></span>400pax Banquet Hall</li>
                                        <li><span><i class="fa-solid fa-check"></i></span>100pax Conference Room</li>

                                    </ul>

                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/WhatsApp Image 2024-08-06 at 12.16.32_6c391f93.jpg" />

                            </div>
                            <div className="facility">
                                <h2>Dining </h2>
                                <div className="more-details">
                                    <ul>
                                        <li><span><i class="fa-solid fa-check"></i></span>Multi cuisine Restaurant</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="../img/parking.jpg" />

                            </div>
                            <div className="facility">
                                <h2>Parking Space</h2>
                                <div className="more-details">
                                    <ul>
                                        <li><span><i class="fa-solid fa-check"></i></span>Amble Car Parking</li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </SwiperSlide>



                </Swiper>
            </div>
        </div>
    )
}

export default BriefFacilities