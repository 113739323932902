import React, { useEffect } from 'react';
import '../../../styles/Footer.css'
import { Link } from 'react-router-dom';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Footer() {

    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Footer",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
                
            }
        })

        tl.from(".Footer .container .col", {
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.4,
            stagger:0.3
        });
        
        


    }, []);

    
    return (
        <div className='Footer'>
            <div className="bg-gradient"></div>
            <div className="container">

                <div className="row">
                    <div className="col">
                        <div className="logo">
                            <img src="../img/logo-white.png" alt="logo" />
                        </div>
                        <a href='tel:+9544900015' className='phone'>Phone: +91 9544900015</a>
                        <a href='mailto:sahyaregency@gmail.com'>sahyaregency@gmail.com</a>

                    </div>
                    <div className="col">
                        <h4>Location</h4>
                        <p>Sahya Regency Kothakurissi Road Vaniyamkulam Palakkad-679522 Kerala, India.</p>

                    </div>
                    <div className="col">
                        <h4>Quick Links</h4>
                        <div className="links">
                            <ul>
                                <li><Link to={'/'}>Home</Link></li>
                                <li><Link to={'/about'}>About</Link></li>
                                <li><Link to={'/facility'}>Facility</Link></li>
                                <li><Link to={'/gallery'}>Gallery</Link></li>
                                <li><Link to={'/contact'}>Contact</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
            <div className="copyright">
                <p>© 2024 Sahya | All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer