import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../../../styles/SlidingTexts.css';

gsap.registerPlugin(ScrollTrigger);

function SlidingTexts() {
  const textRefs = useRef([]);

  useEffect(() => {
    textRefs.current.forEach((text) => {
      gsap.fromTo(
        text,
        { x: '10%' },
        {
          x: '-100%',
          duration: 1.5,
          ease: 'ease-in',
          scrollTrigger: {
            trigger: text,
            start: 'top 100%',
            end: 'top 0%',
            scrub: 3,
            markers:false
          },
        }
      );
    });
  }, []);

  return (
    <div className='sliding-texts'>
      <div className='text' ref={(el) => (textRefs.current[0] = el)}>Enjoy</div>
      <div className='text' ref={(el) => (textRefs.current[1] = el)}>Luxury</div>
      <div className='text' ref={(el) => (textRefs.current[2] = el)}>Holiday</div>
      <div className='text' ref={(el) => (textRefs.current[3] = el)}>Relax</div>
      <div className='text' ref={(el) => (textRefs.current[4] = el)}>Chill</div>
      <div className='text' ref={(el) => (textRefs.current[5] = el)}>Enjoy</div>
      <div className='text' ref={(el) => (textRefs.current[6] = el)}>Luxury</div>
      <div className='text' ref={(el) => (textRefs.current[7] = el)}>Holiday</div>

    </div>
  );
}

export default SlidingTexts;
