import React, { useEffect, useState } from 'react'
import BannerData from '../Components/Helpers/BannerData';
import Navbar from '../Components/Common/Navbar/Navbar';
import BannerSection from '../Components/Common/BannerSection/BannerSection';
import GalleryList from '../Components/GalleryPage/GalleryList/GalleryList';
import { wordpressUrl } from '../Components/Helpers/wordpressUrl';
import axios from 'axios';
import Footer from '../Components/Common/Footer/Footer';

function Gallery() {
    const { GalleryH2, GalleryImg } = BannerData;
    const [gallerydata, setgallery] = useState([]);

    useEffect(() => {

        const id = 10
        axios.get(`${wordpressUrl}wp-json/wp/v2/gallery/${id}`)
            .then(res => {
                setgallery(res.data)
                // console.log(res.data + 'gallery array')
            }).catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className='Gallery'>
            <Navbar />
            <BannerSection h2={GalleryH2} imgUrl={GalleryImg} />
            <GalleryList gallery={gallerydata} />
            <Footer />
        </div>
    )
}

export default Gallery