import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Facility from './Pages/Facility';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import ScrollToTop from './Components/Common/ScrollToTop/ScrollToTop';
import ScrollToAnchor from './Components/ScrollAnchor/ScrollAnchor';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <ScrollToAnchor/>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/facility' element={<Facility />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact' element={<Contact />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
