import React from 'react'
import '../../../styles/BannerSection.css';


import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function BannerSection(props) {
    const { h2, imgUrl } = props;

    useGSAP(() => {
        gsap.from(".parallax-bg", {
            scrollTrigger: {
                trigger: ".parallax-bg",
                toggleActions: "restart pause resume pause",
                start: 'top',
                end: "bottom ",
                scrub: 2,
                // markers: true
            },
            yPercent: "-15",
            duration: 3,

        })

        

    }, [props]);
    return (
        <div className='BannerSection'>
            <div className="img-container">
                <img src={imgUrl} alt="about banner" className='parallax-bg' />
            </div>
            <div className="overlay"></div>
            <div className="text">
                <h2>{h2}</h2>
            </div>
            <ul class="breadcrumb">
                <li><a href="/">Home</a></li>
                <li>{h2}</li>
            </ul>

        </div>
    )
}

export default BannerSection