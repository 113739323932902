import React, { useContext, useEffect, useState } from 'react';
import '../../../styles/Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import MenuContext, { MenuProvider } from '../../../Context/menuProvider';


// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Navbar() {
  const { menuOpen, handleToggleMenu, navbar, handleNavbar, setImage, overlay, image, setOverlay } = useContext(MenuContext);
  const location = useLocation(); // Get the current location

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 768) {
      handleToggleMenu();
    }
  };

  const shouldReload = (path) => {
    // Check if the clicked link matches the current location
    return location.pathname === path;
  };

  
  useGSAP(() => {
        
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".Navbar",
            start: "top 80%",
            end: "top 20%",
            toggleActions: "play none none none",
        }
    })
    
    tl.from(".Navbar .logo", {
       y: -60,
      //  x: 30,
       opacity: 0,
       duration: 1,
       stagger: 0.08,


    });
    tl.from(".Navbar ul li", {
       y: -60,
      //  x: 30,
       opacity: 0,
       duration: 1,
       stagger: 0.08,


    }, "-=1.");
    tl.from(".Navbar .get-in-touch", {
       y: -60,
      //  x: 30,
       opacity: 0,
       duration: 1,
       stagger: 0.08,


    }, "-=1");
    
    


}, []);
  return (
    <div>
      <div className='Navbar'>
        <header className={navbar ? 'header active' : 'header'}>
          <div className="logo">
            <Link to="/"><img src={image} alt="Sahya logo" /></Link>
          </div>
          <nav className='nav-menu'>
            <ul className={menuOpen ? 'nav-links active' : 'nav-links'} >
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/') && window.location.reload(); }} to="/">Home</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/about') && window.location.reload(); }} to="/about">About</Link>
              </li>


              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/facility') && window.location.reload(); }} to="/facility">Facility</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/gallery') && window.location.reload(); }} to="/gallery">Gallery</Link>
              </li>
              

              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/contact') && window.location.reload(); }} to="/contact">Contact</Link>
              </li>

            </ul>
            <div className="menu-button">
              <label htmlFor="check">
                <input type="checkbox" id="check" onClick={handleMenuItemClick} />
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <div className={overlay ? 'overlay active' : 'overlay'}></div>
          </nav>
          <div className="get-in-touch">
            <Link to="/contact/#get-in-touch"><span>Get In Touch</span></Link>
          </div>
          
        </header>
      </div>
    </div>
  );
}

export default Navbar;
