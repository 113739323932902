import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Components/Helpers/BannerData';
import FacilityList from '../Components/FacitlityPage/FacilityList/FacilityList';
import Footer from '../Components/Common/Footer/Footer';
import FacilityDetails from '../Components/FacitlityPage/FacilityDetails/FacilityDetails';

function Facility() {
  const { FacilityH2, FacilityImg } = BannerData;

  return (
    <div className='Facility'>
      <Navbar />
      <BannerSection h2={FacilityH2} imgUrl={FacilityImg} />
      <FacilityList />
      <FacilityDetails/>
      <Footer/>

    </div>
  )
}

export default Facility