

const BannerData = {
    AboutH2: "About",
    AboutImg: "../img/WhatsApp Image 2024-08-06 at 12.16.31_81f2e685.jpg",


    FacilityH2: "Facility",
    FacilityImg: "../img/WhatsApp Image 2024-08-06 at 12.16.31_81f2e685.jpg",

    GalleryH2: "Gallery",
    GalleryImg: "../img/WhatsApp Image 2024-08-06 at 12.16.31_81f2e685.jpg",

    ContactH2: "Contact",
    ContactImg: "../img/WhatsApp Image 2024-08-06 at 12.16.31_81f2e685.jpg",
}

export default BannerData