import React from 'react';
import '../../../styles/BriefAbout.css';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function BriefAbout() {

    useGSAP(() => {
        gsap.from(".small-img", {
            scrollTrigger: {
                trigger: ".small-img",
                toggleActions: "restart pause resume pause",
                start: 'top 100%',
                end: "bottom 10%",
                scrub: 2,
                // markers: true
            },
            y: "50",
            duration: 3,

        })
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".BriefAbout",
                start: "top 50%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".BriefAbout .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.3,
            stagger: 0.06,
        });
        tl.from(".BriefAbout .row .left-col p", {
            opacity: 0,
            duration: 1,
            
        }, "-=1");
        tl.to(".BriefAbout .row .left-col .btn", {
            opacity: 1,
            duration:0.2

        }, "-=0.7");


    }, []);
    return (
        <div className='BriefAbout'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <h3>ABOUT US</h3>
                        <h2>Redefining Your Getaway Journey</h2>
                        <p>At Sahya Regency, we redefine your getaway journey. Our 3-star hotel combines luxurious accommodations with serene surroundings to create the perfect escape. With top-tier facilities, including a grand banquet hall, modern conference room, and diverse dining options, we cater to both business and leisure travelers.</p>
                        <Link to={'/about'} className='btn'>Explore More</Link>
                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">

                        <div className="img-container">
                            <div className="large-img">
                                <img src="img/WhatsApp Image 2024-08-06 at 12.16.34_a1b04e31.jpg" alt="about" />
                            </div>
                            <div className="small-img">
                                <img src="img/sahya-regency-2.png" alt="about" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BriefAbout