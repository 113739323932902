import React from 'react';
import '../../../styles/Mission.css';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Mission() {

    useGSAP(() => {

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Mission",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })


        tl.from(".Mission .card .card-front", {
            rotateY: 180, 
            duration: 1,


        });



    }, []);

    const cards = [
        { title: 'Mission', content: 'To provide unparalleled hospitality and exceptional service, ensuring a memorable stay for every guest.', iconClass: 'fas fa-bullseye' },
        { title: 'Vision', content: 'To be the leading choice for luxury and comfort, redefining the guest experience in the hospitality industry.', iconClass: 'fas fa-eye' },
        { title: 'Values', content: 'Commitment to excellence, customer satisfaction, integrity, and a warm, welcoming atmosphere.', iconClass: 'fas fa-gem' },
    ];

    return (
        <div className='Mission'>
            <div className="container">
                {cards.map((card, index) => (
                    <div className='card' key={index}>
                        <div className='card-inner'>
                            <div className='card-front'>
                                <i className={card.iconClass} ></i>
                                <h2>{card.title}</h2>
                            </div>
                            <div className='card-back'>
                                <p>{card.content}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Mission;
