import React from 'react';
import '../../../styles/Transportation.css'; // Import the CSS file

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function Transportation() {

    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Transportation",
                start: "top 50%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".Transportation .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.2,
            stagger: 0.05,

        });
        tl.to(".Transportation .container p", {
            opacity: 1,
            duration: 1,


        }, "-=1");
        tl.from(".Transportation .container .subsection", {

            opacity: 0,
            duration: 1,
            y:-60,
            stagger:0.4


        }, "-=1");
       
        


    }, []);
    return (
        <div className='Transportation'>
            <div className="container">
                <div className="row">
                    <div className="left-col"data-aos="fade" data-aos-duration="1000">
                        <h3>Transportation</h3>
                        <h2>Convenient Travel Links for Easy Access                        </h2>
                        <p>Sahya Regency is well-connected to major transportation hubs, ensuring easy access for all our guests. We are conveniently located near two major airports:
                        </p>
                    </div>
                    <div className="right-col"data-aos="fade" data-aos-duration="1000">

                        <div className="subsection">
                            <h3>Nearest Airports</h3>
                            <ul>
                                <li>
                                    <i className="fas fa-plane"></i> <p>Calicut: 70km</p>
                                </li>
                                <li>
                                    <i className="fas fa-plane"></i> Kochi: 90km
                                </li>
                            </ul>
                        </div>
                        <div className="subsection">
                            <h3>Nearest Bus Stations</h3>
                            <ul>
                                <li>
                                    <i className="fas fa-bus"></i> Ottapalam
                                </li>
                                <li>
                                    <i className="fas fa-bus"></i> Shoranur
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transportation;
