import React, { useRef, useState } from 'react';
import '../../../styles/ContactForm.css'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function ContactForm() {

    const form = useRef();
    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_z8o33zu', 'template_vyv8a5l', form.current, 'q1eQyWkGrUZ6BfbLw')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your message has been sent successfully.');
                // Reset the form fields after successful submission
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".ContactForm",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".ContactForm .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.2,
            stagger: 0.03,

        });
        
        


    }, []);
    return (
        <div className='ContactForm' id='get-in-touch'>
            <div className="container">
                <div className="row">
                    <div className="left-col">
                        <h2>Stay Tuned & Lets Get In Touch With Us</h2>
                        <p data-aos="fade" data-aos-duration="1000">Have questions or need assistance with your stay? Reach out via the form below or contact us directly. We’re here to make your experience exceptional</p>

                        <ul >
                            <li data-aos="fade" data-aos-duration="1000" data-aos-delay="0"><span><i class="fa-solid fa-location-dot"></i></span>Sahya Regency Kothakurissi Road Vaniyamkulam Palakkad-679522 Kerala, India.</li>
                            <li data-aos="fade" data-aos-duration="1000" data-aos-delay="100"><span><i class="fa-regular fa-envelope"></i></span><a href='mailto:sahyaregency@gmail.com'>sahyaregency@gmail.com</a></li>
                            <li data-aos="fade" data-aos-duration="1000" data-aos-delay="200"><span><i class="fa-solid fa-phone-volume"></i></span><a href='tel:9544900015'>+91-9544900015 (Hotel Phone No)</a></li>
                            <li data-aos="fade" data-aos-duration="1000" data-aos-delay="200"><span><i class="fa-solid fa-phone-volume"></i></span><a href='tel:9544900016'>+91-9544900016</a></li>

                        </ul>

                        <div className="social-media-icons">
                            <h3 data-aos="fade" data-aos-duration="1000">Connect Us Via</h3>

                            <a href="https://www.facebook.com/#/"data-aos="fade" data-aos-duration="1000" data-aos-delay="0" target="_blank" rel="noopener noreferrer">
                                <i class="fa-brands fa-facebook"></i>

                            </a>
                            <a href="https://www.instagram.com/#/" target="_blank"data-aos="fade" data-aos-duration="1000" data-aos-delay="100"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                            <a href="https://wa.me/#" target="_blank"data-aos="fade" data-aos-duration="1000" data-aos-delay="200"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-whatsapp"></i>
                            </a>

                        </div>
                    </div>
                    <div className="right-col">
                        <h3 data-aos="fade" data-aos-duration="1000">Send Us a Message</h3>
                        <form ref={form} onSubmit={sendEmail} data-aos="fade" data-aos-duration="1000">
                            <div>
                                <input type='text' id='name' name='name' value={formData.name} onChange={handleInputChange} placeholder='Name' required title="Enter your full name(maximum 30 characters)" maxLength={30} />
                            </div>
                            <div>
                                <input type='email' id='email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' required />
                            </div>
                            <div>
                                <input type='text' id='phone' name='phone' value={formData.phone} onChange={handleInputChange} placeholder='Phone' required pattern="[0-9]{10}" title="Please enter a valid 10-digit mobile number" />
                            </div>
                            <div>
                                <textarea id='message' name='message' value={formData.message} onChange={handleInputChange} placeholder='Message' rows={4} maxLength={500} title="Enter your message (maximum 500 characters)" required />
                            </div>
                            <div className="bottom-section">
                                <ReCAPTCHA
                                    sitekey="6LfzhSYqAAAAAAK7Zd8d8Fy0M4zMl9-i4B2-MAMH"
                                    onChange={onChange}
                                />
                                <button type='submit' disabled={!verified}>Submit</button>

                            </div>

                        </form>

                    </div>
                </div>
                <div className='bottom-section' data-aos="fade" data-aos-duration="1000">


                    <iframe
                        src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3919.3674429134167!2d76.327530375042!3d10.783143989366057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQ2JzU5LjMiTiA3NsKwMTknNDguNCJF!5e0!3m2!1sen!2sin!4v1720011437920!5m2!1sen!2sin'
                        width='100%'
                        height='100%'
                        allowFullScreen=''
                        loading='lazy'
                        referrerPolicy='no-referrer-when-downgrade'
                    ></iframe>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default ContactForm