import React from 'react';
import '../../../styles/CompanyOverview.css';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function CompanyOverview() {

    useGSAP(() => {
        gsap.from(".small-img", {
            scrollTrigger: {
                trigger: ".small-img",
                toggleActions: "restart pause resume pause",
                start: 'top 100%',
                end: "bottom 10%",
                scrub: 2,
                // markers: true
            },
            y: "50",
            duration: 3,

        })

        // timeline
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".CompanyOverview",
                start: "top 50%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".CompanyOverview .container h2", { type: "chars" });

        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.3,
            stagger: 0.06,

        });
        tl.from(".CompanyOverview .row .left-col p", {
            opacity: 0,
            duration: 1,

        });
        


    }, []);
    return (
        <div className='CompanyOverview'>
            <div className="container">
                <div className="row">
                    <div className="left-col" >
                        <h3>Company Overview</h3>
                        <h2>Our Story</h2>
                        <p>Sahya Regency is a premier 3-star hotel offering an exceptional blend of luxury, comfort, and serene surroundings. Located near to ottappalam, our hotel is designed to cater to both business and leisure travelers. With our state-of-the-art facilities, including a spacious banquet hall, well-equipped conference room, and a multi-cuisine restaurant, we provide an ideal setting for a memorable stay.
                            <br></br>
                            <br></br>
                            At Sahya Regency, we are committed to providing outstanding service and a warm, welcoming atmosphere. Our elegant accommodations range from luxurious suites to well-appointed rooms, all featuring modern amenities such as high-speed Wi-Fi, air conditioning, and convenient laundry services.
                            <br></br>
                            <br></br>
                            Our dedicated team is focused on delivering personalized services to ensure every guest enjoys a delightful experience. Whether you're attending an event, hosting a business meeting, or simply seeking a relaxing getaway, Sahya Regency is your perfect destination.
                            <br></br>
                            <br></br>
                            Discover the comfort, elegance, and convenience that make Sahya Regency a standout choice for discerning travelers.</p>
                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">

                        <div className="img-container">
                            <div className="large-img">
                                <img src="img/WhatsApp Image 2024-08-06 at 12.16.33_49305123.jpg" alt="about" />
                            </div>
                            <div className="small-img">
                                <img src="img/sahya-regency.jpg" alt="about" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyOverview