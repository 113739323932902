import React from 'react';
import '../../../styles/KeyMessage.css'


// gsap importing 
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import SplitType from 'split-type'
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

function KeyMessage() {

    useGSAP(() => {
        var split = new SplitType(".KeyMessage .content h3", { type: "chars" });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".KeyMessage .content h3",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        tl.from(".KeyMessage", {
            opacity:0,
           scale:0.5,
           duration:1
        });
        tl.from(split.words, {
            y: -60,
            // x: 60,
            opacity: 0,
            duration: 0.7,
            stagger: 0.2,

        });
        // tl.from(".KeyMessage .content ul span", {
        //     x: 30,
        //     opacity: 0,
        //     duration: 0.3,
        //     stagger: 0.07,
        // });
        tl.from(".KeyMessage .content h2", {
            opacity: 0,
            duration: 1,


        }, "-=1");
        
    }, []);

    
    return (
        <div className='KeyMessage'>
            <video autoPlay muted loop className='background-video'>
                <source src='video/food.mp4' type='video/mp4' />
            </video>

            <div className='content'>
                {/* <ul className="three-star-icons">
                    <span><i class="fa-solid fa-star"></i></span>
                    <span><i class="fa-solid fa-star"></i></span>
                    <span><i class="fa-solid fa-star"></i></span>
                </ul> */}
                <h3>Star Facility Hotel</h3>
                <h2>Relax in Comfort, Rejuvenate in Peace</h2>

            </div>

            <div className="overlay">

            </div>
        </div>
    )
}

export default KeyMessage