import React from 'react';
import '../../../styles/FacilityDetails.css';

// gsap 

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import SplitType from 'split-type';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function FacilityDetails() {

    useGSAP(() => {
        
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".FacilityDetails",
                start: "top 80%",
                end: "top 20%",
                toggleActions: "play none none none",
            }
        })
        var split = new SplitType(".FacilityDetails .container h3", { type: "chars" });
        tl.from(".FacilityDetails .container .row", {
            // y: -60,
            y: 40,
            opacity: 0,
            duration: 0.5,
            stagger: 1,

        });
        tl.from(split.chars, {
            // y: -60,
            y: -20,
            x: 30,
            opacity: 0,
            duration: 0.2,
            stagger: 0.03,

        });

        tl.to(".FacilityDetails .container  p", {
            opacity: 1,
            duration: 1,
            stagger: 2,



        }, "-=2.4");
        
    }, []);


    return (
        <div className='FacilityDetails'>
            <div className="container">
                <div className="row">
                    <h3>Facilities And Amenities Provided On A Complimentary Basis</h3>
                    <p>Left Luggage facilities, Coffee/Tea maker, Buffet Breakfast(Indian &  continental) Dental and Shaving kit, Shampoo, Soap, Moisturizer, Sewing kit, Shoe Shine,Two bottles of purified drinking water (1000 ML each) and 24hrs WIFI, Parking, News Paper. <br></br><br></br> On Request - iron Box and ironing board, Wheel Chair
                    </p>

                </div>
                <div className="row">
                    <h3>Facilities On Request On Chargeable Basis
                    </h3>
                    <p>Travel Desk, Doctor on Call, Coffee Shop, Multi Cosine Restaurant, Conference Hall, Laundry Services, Mini Bar Items.Foregin Currency Exchange</p>
                </div>
            </div>
        </div>
    )
}

export default FacilityDetails